

































































































































































































































































































































.form-title{
  padding: 10px 0 10px 0;
  background: white;
  height: 90%;
  align-self: center;
}
.title-section{
    font-size: 20px;
    font-weight: bold;
}

.create-events-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-body{
  padding: 10px 0 10px 0;
  background: white;
  height: 90%;
  align-self: center;
}
.form-date{
  padding: 10px 0 10px 0;
  background: white;
  height: 90%;
  align-self: center;
}
.form-tag{
  padding: 10px 0 10px 0;
  background: white;
  height: 90%;
  align-self: center;
}
.tag{
  border: 1px solid black;
  text-align: center;
  font-size: 12px;
  border-radius: 50px;
  width: 200px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
}
.tag-container{
  display: flex;
  flex-direction: row;
}
.close{
  margin-right: 15px;
}
.close:hover{
  cursor: pointer;
}
#edit-hv-id{
  width: 50px;
}
